import ThemisChannelLinkDisplayName from "@/components/channel/link-display-name"
import ThemisChannelAiDigitalAgent from "@/components/channel/ai-digital-agent"
import { mapActions, mapGetters } from "vuex"
import {
  LINK_DISPLAY_NAME_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL,
  TRANSLATION_UPLOAD_STATUS
} from "@/constants"

export default {
  name      : "ChannelSidePanel",
  components: {
    ThemisChannelLinkDisplayName,
    ThemisChannelAiDigitalAgent
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      channels                                 : "channels/channels",
      isUpdatingLinkDisplayNameTranslations    : "channels/isUpdatingLinkDisplayNameTranslations",
      isLinkDisplayNameTranslationsUpdated     : "channels/isLinkDisplayNameTranslationsUpdated",
      isDownloadingLinkDisplayNameTranslation  : "channels/isDownloadingLinkDisplayNameTranslation",
      isUploadingLinkDisplayNameTranslationFile: "channels/isUploadingLinkDisplayNameTranslationFile",
      isLinkDisplayNameTranslationFileUploaded : "channels/isLinkDisplayNameTranslationFileUploaded"
    }),
    currentHash() {
      return this.$route.hash
    },
    channel() {
      return this.channels.find(channels => channels.id === +this.$route.params.id)
    },
    isTranslationEnabled() {
      return this.channel?.allowLinkDisplayNameTranslations
    },
    linkDisplayNameTranslationUploadStatus() {
      return this.channel?.linkDisplayNameTranslationUploadStatus
    }
  },
  methods: {
    ...mapActions({
      notify                                : "shared/notify",
      updateChannel                         : "channels/updateChannel",
      loadChannels                          : "channels/loadChannels",
      uploadLinkDisplayNameTranslationFile  : "channels/uploadLinkDisplayNameTranslationFile",
      downloadLinkDisplayNameTranslationFile: "channels/downloadLinkDisplayNameTranslationFile"
    }),
    handleUpdateChannel(payload) {
      this.updateChannel(payload)
    },
    handleUploadTranslationFile(payload) {
      this.uploadLinkDisplayNameTranslationFile(payload)
    },
    handleDownloadTranslationFile(payload) {
      this.downloadLinkDisplayNameTranslationFile(payload)
    },
    clearTranslationFileUploadStatusPollingInterval() {
      if (this.translationFileUploadStatusPolling) {
        clearInterval(this.translationFileUploadStatusPolling)
        this.translationFileUploadStatusPolling = null
      }
    }
  },
  watch: {
    isLinkDisplayNameTranslationsUpdated: {
      handler: function(value) {
        if (value) {
          if (!this.isTranslationEnabled) {
            this.notify({
              type: "success",
              text: "2469"
            })
          }
        }
      }
    },
    linkDisplayNameTranslationUploadStatus: {
      handler: function(newValue, oldValue) {
        if (newValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.translationFileUploadStatusPolling = setInterval(() => {
            this.loadChannels({ id: this.channel.id, uploadStatus: newValue })
          }, LINK_DISPLAY_NAME_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL)
        }
        if (newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS || newValue === TRANSLATION_UPLOAD_STATUS.FAILURE) {
          this.clearTranslationFileUploadStatusPollingInterval()
        }
        if (newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS && oldValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.notify({
            type: "success",
            text: "2494"
          })
        }
      }
    }
  }
}