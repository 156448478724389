import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import {
  ARRAY,
  FILE_NAME,
  TRANSLATION_UPLOAD_STATUS,
  TRANSLATION_FILE_UPLOAD_TYPE,
  TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES
} from "@/constants"

export default {
  name      : "ChannelLinkDisplayName",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data() {
    return {
      showDisableTranslationDialog: false,
      localChannel                : undefined
    }
  },
  props: {
    pChannel                                  : Object,
    pIsUpdatingLinkDisplayNameTranslations    : Boolean,
    pIsLinkDisplayNameTranslationsUpdated     : Boolean,
    pIsDownloadingLinkDisplayNameTranslation  : Boolean,
    pIsUploadingLinkDisplayNameTranslationFile: Boolean
  },
  methods: {
    handleTranslationChange() {
      if (this.isTranslationEnabled) {
        this.showDisableTranslationDialog = true
      } else {
        this.$emit("updateChannel", {
          id                              : this.localChannel.id,
          allowLinkDisplayNameTranslations: this.localChannel.allowLinkDisplayNameTranslations
        })
      }
    },
    resetTranslationChange() {
      this.showDisableTranslationDialog                  = false
      this.localChannel.allowLinkDisplayNameTranslations = this.pChannel.allowLinkDisplayNameTranslations
    },
    openFileExplorerToSelectDocument() {
      this.$refs.input_upload_translation_document.click()
    },
    onDocumentSelection(event) {
      const file = event.target.files[ARRAY.FIRST]
      if (file.size < TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES) {
        this.$emit("uploadTranslationFile", {
          channel: this.pChannel,
          file   : file
        })
      }
    },
    clearTranslationFileUploadStatusPollingInterval() {
      if (this.translationFileUploadStatusPolling) {
        clearInterval(this.translationFileUploadStatusPolling)
        this.translationFileUploadStatusPolling = null
      }
    },
    handleDownloadLinkDisplayNameTranslations() {
      this.$emit("downloadTranslationFile", {
        id  : this.localChannel.id,
        name: FILE_NAME.CHANNEL_LINK_DISPLAY_NAME_TRANSLATIONS(this.localChannel.name)
      })
    }
  },
  computed: {
    isTranslationEnabled() {
      return this.pChannel.allowLinkDisplayNameTranslations
    },
    allowedTranslationDocumentType() {
      return Object.values(TRANSLATION_FILE_UPLOAD_TYPE).toString()
    },
    linkDisplayNameTranslationUploadStatus() {
      return this.pChannel?.linkDisplayNameTranslationUploadStatus
    },
    isLinkDisplayNameTranslationUploadInitiated() {
      return this.linkDisplayNameTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.INITIATED
    },
    isLinkDisplayNameTranslationUploadFailed() {
      return this.linkDisplayNameTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.FAILURE
    },
    isLinkDisplayNameTranslationUploadSuccess() {
      return this.linkDisplayNameTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.SUCCESS
    }
  },
  watch: {
    pChannel: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localChannel) {
          this.localChannel = { ...newValue }
        }
      }
    },
    pIsLinkDisplayNameTranslationsUpdated: {
      handler: function(value) {
        if (value) {
          if (!this.isTranslationEnabled) {
            this.showDisableTranslationDialog = false
          }
        }
      }
    },
    pIsUpdatingLinkDisplayNameTranslations: {
      handler: function(newValue) {
        this.$DECISIONS.DISABLE_LINK_DISPLAY_NAME_TRANSLATION.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.DISABLE_LINK_DISPLAY_NAME_TRANSLATION.pActions[1].buttonProps.loading  = newValue
      }
    }
  }
}