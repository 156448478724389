import ThemisInput from "@/components/shared/input"

export default {
  name      : "AiDigitalAgentSidePanel",
  components: {
    ThemisInput
  },
  data() {
    return {
      localChannel              : undefined,
      selectedDigitalAgentChoice: null,
      digitalAgentPolicy        : null,
      itemsForAiAgentSettings   : [{
        value      : false,
        name       : this.$t("2572"),
        description: this.$t("2573")
      }, {
        value      : true,
        name       : this.$t("2574"),
        description: this.$t("2575")
      }]
    }
  },
  props: {
    pChannel: Object
  },
  methods: {
    handleSaveAgentConfigurations() {
      if (this.changeInDigitalAgentChoice || this.changeInPolicyForAgent) {
        const updatePayload = {
          id: this.pChannel.id
        }

        if (this.changeInDigitalAgentChoice) {
          updatePayload.digitalAgentChoice = this.selectedDigitalAgentChoice
        }

        if (this.changeInPolicyForAgent) {
          updatePayload.policyForAgent = this.digitalAgentPolicy
        }

        this.$emit("updateChannel", updatePayload)
      }
    }
  },
  computed: {
    disableSaveChangesButton() {
      return !(this.changeInDigitalAgentChoice || this.changeInPolicyForAgent)
        || this.selectedDigitalAgentChoice === null
    },
    digitalAgentChoice() {
      return this.pChannel?.digitalAgentChoice
    },
    policyForAgent() {
      return this.pChannel?.policyForAgent
    },
    changeInDigitalAgentChoice() {
      return this.selectedDigitalAgentChoice !== this.digitalAgentChoice
    },
    changeInPolicyForAgent() {
      return this.digitalAgentPolicy !== this.policyForAgent
    }
  },
  watch: {
    digitalAgentChoice: {
      immediate: true,
      handler  : function(newValue) {
        this.selectedDigitalAgentChoice = newValue
      }
    },
    policyForAgent: {
      immediate: true,
      handler  : function(newValue) {
        this.digitalAgentPolicy = newValue
      }
    }
  }
}